//neutral
$black-color: #000000;
$white-color: #ffffff;
$basic005: #5A6E7B;
$basic004: #8A9FAB;
$basic003: #BCCCD6;
$basic002: #EAF0F5;
$basic001: #F9FAFB;

//Primary
$KTInfinitasBlue: #000D36;
$KTBlue-Dark: #007BC4;
$KTBlue: #00A6E6;
$KTBlue-Lighter: #04BAEE;
$KTBlue-Lightest: #DFF1FC;

//Accent
$KTYellow: #EC920E;
$KTYellow-Lighter: #FAB900;
$KTYellow-Lightest: #FFD800;

$Green: #63BE00;
$Green-Lighter: #88E226;
$Green-Lightest: #A6F056;

$Red: #DB0000;
$Red-Lighter: #FF4A4B;
$Red-Lightest: #FF7A7A;

$KrungthaiFast-BoldItalic: KrungthaiFast-BoldItalic;
$KrungthaiFast-Bold: KrungthaiFast-Bold;
$KrungthaiFast-LightItalic: KrungthaiFast-LightItalic;
$KrungthaiFast-Light: KrungthaiFast-Light;
$KrungthaiFast-Regular: KrungthaiFast-Regular;
$KrungthaiFast-UltraLightItalic: KrungthaiFast-UltraLightItalic;
$KrungthaiFast-UltraLight: KrungthaiFast-UltraLight
