.layout-sidebar {
  width: 250px;
  min-height: calc(100vh - 12rem);
  overflow-y: auto;
  user-select: none;
  transition: transform $transitionDuration, left $transitionDuration;
  padding: 16px;
  margin: 1.5rem 0 2rem 0;
  background: linear-gradient(85.16deg, #000d36 0%, #3476a6 100%);
  box-shadow: 2px 0px 4px rgba(188, 204, 214, 0.4);
  border-radius: 0px 16px 16px 0px;
  position: relative;
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      padding: 16px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      color: $white-color;
    }

    .layout-menuitem-root-text:hover {
      padding: 16px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      color: $white-color;
      background: $KTInfinitasBlue;
      cursor: pointer;
    }

    .disabled.layout-menuitem-root-text {
      pointer-events: none;
      // show menu-bar
      display: none;
    }

    .disabled.layout-menuitem-root-text:hover {
      padding: 16px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      color: $white-color;
      background: none;
    }

    > a {
      display: none;
    }

    > .active-route {
      font-weight: 700;
      color: $KTInfinitasBlue;
      background-color: $white-color;
    }
  }

  a {
    user-select: none;

    &.active-menuitem {
      > .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  // li:last-child {
  //   background: rgba(249, 250, 251, 0.1);
  //   border: 1px solid #FFFFFF;
  //   border-radius: 8px;
  //   position: absolute;
  //   bottom: 24px;
  //   min-width: 218px;

  //   .ico {
  //     content: '';
  //     background: url('/assets/icons/logout-icon.svg');
  //     background-size: cover;
  //     // position: absolute;
  //     display: inline-block;
  //     vertical-align: middle;
  //     width: 24px;
  //     height: 24px;
  //     padding-left: 20px;
  //   }
  // }

  ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: var(--text-color);
      cursor: pointer;
      padding: 0.75rem 1rem;
      border-radius: $borderRadius;
      transition: background-color $transitionDuration,
        box-shadow $transitionDuration;

      .layout-menuitem-icon {
        margin-right: 0.5rem;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      &.rotated-icon {
        .layout-menuitem-icon {
          transform: rotate(90deg);
        }
      }

      &.active-route {
        font-weight: 700;
        color: var(--primary-color);
      }

      &:hover {
        background-color: var(--surface-hover);
      }

      &:focus {
        @include focused-inset();
      }
    }

    ul {
      overflow: hidden;
      border-radius: $borderRadius;

      li {
        a {
          margin-left: 1rem;
        }

        li {
          a {
            margin-left: 2rem;
          }

          li {
            a {
              margin-left: 2.5rem;
            }

            li {
              a {
                margin-left: 3rem;
              }

              li {
                a {
                  margin-left: 3.5rem;
                }

                li {
                  a {
                    margin-left: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// @media only screen and (min-height: 800px) {
//   .layout-menu {
//     .layout-root-menuitem {
//     > .layout-menuitem-root-text {
//       padding: 1.6vh 16px 1.6vh 16px;
//     }

//     .layout-menuitem-root-text:hover {
//       padding: 1.6vh 16px 1.6vh 16px;
//     }

//     .disabled.layout-menuitem-root-text:hover {
//       padding: 1.6vh 16px 1.6vh 16px;
//     }}
//   }
// }

@media only screen and (max-height: 800px) {
  .layout-menu {
    .layout-root-menuitem {
      > .layout-menuitem-root-text {
        padding: 1.6vh 16px 1.6vh 16px;
      }

      .layout-menuitem-root-text:hover {
        padding: 1.6vh 16px 1.6vh 16px;
      }

      .disabled.layout-menuitem-root-text:hover {
        padding: 1.6vh 16px 1.6vh 16px;
      }
    }
  }
}

@media only screen and (max-height: 650px) {
  .layout-menu {
    .layout-root-menuitem {
      > .layout-menuitem-root-text {
        padding: 1vh 16px 1vh 16px;
      }

      .layout-menuitem-root-text:hover {
        padding: 1vh 16px 1vh 16px;
      }

      .disabled.layout-menuitem-root-text:hover {
        padding: 1vh 16px 1vh 16px;
      }
    }
  }
}

// @media only screen and (max-height: 800px) {
//   .layout-menu {
//     .layout-root-menuitem {
//     > .layout-menuitem-root-text {
//       padding: 12px 16px 12px 16px;
//     }

//     .layout-menuitem-root-text:hover {
//       padding: 12px 16px 12px 16px;
//     }

//     .disabled.layout-menuitem-root-text:hover {
//       padding: 12px 16px 12px 16px;
//     }}
//   }
// }

// @media only screen and (max-height: 720px) {
//   .layout-menu {
//     .layout-root-menuitem {
//     > .layout-menuitem-root-text {
//       padding: 10px 16px 10px 16px;
//     }

//     .layout-menuitem-root-text:hover {
//       padding: 10px 16px 10px 16px;
//     }

//     .disabled.layout-menuitem-root-text:hover {
//       padding: 10px 16px 10px 16px;
//     }}
//   }
// }

// @media only screen and (max-height: 640px) {
//   .layout-menu {
//     .layout-root-menuitem {
//     > .layout-menuitem-root-text {
//       padding: 6px 16px 6px 16px;
//       animation: slideUp500 1s forwards;
//     }

//     .layout-menuitem-root-text:hover {
//       padding: 6px 16px 6px 16px;
//       animation: slideUp500 1s forwards;
//     }

//     .disabled.layout-menuitem-root-text:hover {
//       padding: 6px 16px 6px 16px;
//       animation: slideUp500 1s forwards;
//     }}
//   }
// }

// @keyframes slideUp500 {
//   0%    { transform: translate3d(0,0,0); }
//   100%  { transform: translate3d(0,0,0); }
// }
