.layout-footer {
    transition: margin-left $transitionDuration;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    background: $white-color;
    box-shadow: 0px -2px 4px rgba(188, 204, 214, 0.4);
    height: 3rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;

    h6 {
      font-size: 12px;
      font-weight: 400;
      color: $black-color;
    }
}
