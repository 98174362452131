.layout-main-container {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 11.5rem);
    justify-content: space-between;
    padding-top: 5rem;
    padding-bottom: 3rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: auto;
    padding: 1.5rem;
    max-width: calc(100% - 250px);
    max-height: 85vh;
    overflow-y: scroll;
}
