/* You can add global styles to this file, and also import other style files */
@import "variables.scss";
@import "bootstrap/scss/bootstrap";
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@font-face {
  font-family: KrungthaiFast-BoldItalic;
  src: url(assets/fonts/KrungthaiFast-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: KrungthaiFast-Bold;
  src: url(assets/fonts/KrungthaiFast-Bold.ttf) format("truetype");
}

@font-face {
  font-family: KrungthaiFast-LightItalic;
  src: url(assets/fonts/KrungthaiFast-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: KrungthaiFast-Light;
  src: url(assets/fonts/KrungthaiFast-Light.ttf) format("truetype");
}

@font-face {
  font-family: KrungthaiFast-Regular;
  src: url(assets/fonts/KrungthaiFast-Regular.ttf) format("truetype");
}

@font-face {
  font-family: KrungthaiFast-UltraLightItalic;
  src: url(assets/fonts/KrungthaiFast-UltraLightItalic.ttf) format("truetype");
}

@font-face {
  font-family: KrungthaiFast-UltraLight;
  src: url(assets/fonts/KrungthaiFast-UltraLight.ttf) format("truetype");
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
span,
pre {
  font-family: $KrungthaiFast-Regular;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
